var posts = {
    "posts": [
        {
            "week": "prework week 1",
            "prompt": "Was your first week what you expected? Why? Why not? What are you struggling with? What are you excited or eager to learn more about?",
            "post": "The first week of prework is complete and it is (kind of) what I expected! I like learning this way and doing the stand-up meetings. I’m still a little confused as to how git works, although I’m sure it will click more when we actually write code and use git. I’m excited to move on to html!"
        },
        {
            "week": "prework week 2",
            "prompt": "What are your thoughts on Pseudo Coding? What are you struggling with? What are you excited or eager to learn more about?",
            "post": "I think pseudo-coding is a great way to plan out and design whatever it is you are developing. It saves time in the long run because you have a defined plan. To me, it is comparable to drafting an outline when writing an essay. Everything is going great during the pre work and nothing has been too much trouble for me. Excited to move onto html, css, and javascript!"
        },
        {
            "week": "prework week 3",
            "prompt": "What are your thoughts about the roles HTML, CSS, and Javascript play in the process of rendering content and providing user experience? What are some objects that your blog incorporates?",
            "post": "To my knowledge, html is the structure of content on the page, css is the styling, and javascript is the “brain”, used to make things happen."
        },
        {
            "week": "prework week 4",
            "prompt": "What was one thing you liked and disliked about each of the new topics covered in Prework? What was helpful? What hindered your progress? How did you overcome challenges?",
            "post": "I liked learning how html, css, and javascript are all used together and what the purpose of each one is. One thing I would change, would be to learn git AFTER learning html,css, and a bit of js. At first, it didn't really click with me as to what it was really used for. Other than that, everything was great. It would have made it a bit easier if for the first 2 days I wasn’t driving home from utah and having to do everything from the back of a car! Luckily with other people to drive, a pair of good headphones, and a mobile hotspot, I was able to keep up! The stand-ups were a huge help as well."
        },
        {
            "week": "week 1",
            "prompt": "What did you learn about that helped you understand more about Design? What do you want to continue learning about that helps you understand Design better? What Design aspects are important to you as a user and as a developer? What is the number one thing that held you back this week? Why? What is one thing you would like to get better at in bootcamp?",
            "post": "This week we learned how to use bootstrap for styling as well as how to use good design practices! It makes styling so much faster and cleaner, so you can spend more time on the actual code of a project. I want to continue to learn good “c.r.a.p.” principles so I can make the best use out of the tools that bootstrap provides. As a developer, the most important design aspects to me are those in which help the user understand the message or purpose of a website, such as the use of white space and alignment. One thing that held me back this week was lack of pseudocoding. It is so much easier to have a plan before you begin to work on something. You wouldn’t build a house without blueprints, would you?"
        },
        {
            "week": "week 2",
            "prompt": "What did you learn about that helped you understand the Development process? What does it mean to develop good code? What does it mean to be a good Developer? What is one thing that programmers hate doing?",
            "post": "Learning more about good pseudocode practices has really helped me better understand the development process. Writing good code is very important too, it means to write code that is clean, concise, and readable to others. This also ties into being a good developer- using the best practices and being efficient. One thing programmers hate doing is struggling in trying to read someone elses code."
        },
        {
            "week": "week 3",
            "prompt": "What are three ideas you might want to do for your final project Why do you see Javascript useful or hard to use? What aspects about Javascript are you interested in learning more about?",
            "post": "I have not quite come up with any solid ideas for my final project, but I would like to incorporate something music related. I will keep trying to brainstorm in the meantime. I currently see javascript useful and hard(ish) to use. It’s useful for making a dynamic web-app, but since I am early on in learning it, it can all seem a bit overwhelming at times. I am interested in learning more about the best practices in writing good javascript code."
        },
        {
            "week": "week 4",
            "prompt": "Why do you see Javascript Frameworks and Libraries useful or hard to use? What Frameworks or Libraries are you interested in learning more about?",
            "post": "I see javascript frameworks and libraries very useful. A good library is similar to building with legos. A lot of premade functions and other things to make your life easier and your code more concise. Frameworks are good for setting up the, well, frame of your project and giving you everything you need to start. "
        },
        {
            "week": "week 5",
            "prompt": "Why are CLIs scary? What have you learned about CLIs? Would you build a CLI? What would it do? List a some ideas that without a Framework, would take you a long time to code How do you feel now (week 5) compared to when you started?",
            "post": "CLI’s seem scary because there is not quite a UI that we are used to. Instead it is just straight up entering commands into the command line. So far I know that we use git, and we can also install different packages, edit files, and much more. If I built a CLI, I would make it set up my project for me with preferences that I like. \n I can definitely tell that I have been learning a lot, and while I would say my confidence has gone up a bit, it comes and goes. This is because whenever new things are introduced, I jump back off the “cliff of confusion”. It is an upwards trend with many ups and downs in between!"
        },
        {
            "week": "week 6",
            "prompt": "What do you like about React? What are you struggling with the most about React? How has React caused you to rethink your existing projects? Biggest win this week!",
            "post": "I really like the jsx files in react. Having the javascript and html combined is really cool and useful. It makes things easy instead of having to use a bunch of getelementbyid() and similar functions. I am struggling the most with figuring out the best practices. While I write code that works, I am not sure if I am following the best practices. I would imagine that the tic tac toe would have been much easier in React. "
        },
        {
            "week": "week 7",
            "prompt": "What have you learned about PHP and server side programming that you were not aware of prior to this week?What do you want to know more about PHP and server side programming? How has learning PHP been? What are some similarities you can see with JS; differences? What if you had started with PHP instead of JS? Are there any exercises you think would be improved if you refactored them to PHP from JS? Do you plan to?",
            "post": "I learned that languages like php run on the server and can also connect to a database. Also, code that runs on the server instead of in the browser cannot be accessed by the user, so that is where you write the important code that may contain things you want to keep private. I want to know what more you can do with server side code other than connect to a db! \n Learning php has been a little weird. I see some similarities with javascript during our hackerranks, but I also see a lot of differences. The documentation can be a little confusing, too. I think if I started with php instead, things would be more or less the same, and i would find javascript weird once I learned it. "
        },
        {
            "week": "week 8",
            "prompt": "What are your thoughts about how far you have come? How has your Digital Literacy, Computational Thinking, and Adoption of Coding Culture increased? How do you plan on continuing to grow those areas? What are you struggling with the most in the Tech Stack so far and why? What were your main takeaways from the career fair? After making it through the tech stack, where do you think your current gaps are",
            "post": "I feel like I have come a long way since I started, but I also have a long way to go. After All this is an ever changing industry and you must constantly keep learning. One thing I have noticed is how I approach problems, and how I go about solving them. I want to continue to grow as a developer by doing fun projects that incorporate something new each time. \n My biggest struggle so far was the initial javascript projects, they were a lot to take in and challenging. However I jumped off of the cliff of confusion and eventually it started clicking. My current struggle is making sense of php for a backend api and understanding how it all works. "
        },
        {
            "week": "week 9",
            "prompt": "What are your thoughts about Laravel and React? What is one thing that you love about React or Laravel that you used to hate/ didn't understand how to use what unique JS or PHP library will you implement for your final project? What cool new features have you learned about from the languages and frameworks you already know that will improve your workflow / add slick features to your final project how many jobs have you applied to? have you been refining your resume/cover letter for the job? have you been trying to learn the code that the specific company uses? added any new projects to your resume/github yet?",
            "post": "I really enjoy using Laravel and React. Laravel sets you up with everything you need to start your project, and React is such a useful library. One thing I love about react that I used to hate was state. I just didn't really understand it. Now however, I understand its use and I think it is such an amazing feature. It really keeps the code clean and make it super easy to update and then dynamically re-render your app. I have only been in talks with one job, but I am starting my search soon (spending all my time learning right now!). "
        },
        {
            "week": "week 10",
            "prompt": "What are your thoughts about the Final Project? What are you most excited about? What do you think you will struggle with in completing the Final Project?",
            "post": "I am excited for the final project and finally getting to create something that I came up with! I am going to make an automatic spotify playlist curator for people hosting social events. Based on what type of event they are hosting, the web-app will create a playlist of music on their spotify account based on a number of different parameters such as tempo, danceability (yes spotify tracks that), presence of lyrics, genre, and much more. I am excited to be able to show this to people and have them try it out. I think I will struggle the most with the node js part of this project. "
        },
        {
            "week": "week 11",
            "prompt": "How have you progressed and struggled during the time while building your Final Project?",
            "post": "The hardest part of this project was probably getting the backend all setup and working correctly because it's all still relatively new! But because of that I have learned so much more and my confidence has gone way up. I really feel good about it!"
        },
        {
            "week": "week 12",
            "prompt": "What was your experience like overall in the Bootcamp? When you look back on this blog, where do you see yourself growing the most?",
            "post": "Doing this bootcamp was one of the best decisions I have made. For the first time in a while (in terms of education) I was so interested in what I was doing. I would leave bootcamp, go home, eat, and then open the laptop back up and keep leaning. I loved making friends with everyone in the bootcamp, its an amazing group. Justin and Ian are amazing teachers. When I look back on this blog I see myself growing the most after the initial javascript weeks. It was grindy and gritty and some long nights, but I pushed through it and grew a lot as a developer. This is only the beginning! "
        }
    ]
}

export default posts