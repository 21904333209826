import React from 'react';
import posts from './Posts';
import './blog.css';

export default class Blog extends React.Component {

    constructor() {
        super();
        this.state = {
            posts: posts.posts.reverse(),
        }
    }

    componentDidMount() {
        console.log(this.state.posts)
    }

    render() {
        return (
            <div className="blog container-fluid">
                <h1 className="border-bottom">Bootcamp Blog</h1>
                <div className="row">
                    <div className="col-sm-12 col-md-10 col-lg-8 mx-auto">

                        {this.state.posts.map((post, idx) => {
                            return (
                                <div key={idx}>
                                    <h2>{post.week}</h2>
                                    <p className="text-muted">{post.prompt}</p>
                                    <p>{post.post}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}